<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <el-link type="primary" style="font-weight: bold; font-size: 19px" @click="$router.push({ name: 'notifi' })">お知らせメール一覧 </el-link>
        <i class="fas fa-angle-right breadcrumb-icon"></i>
        <span style="color: #66B0FE; font-weight: bold;">メール送信</span>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">
      <div class="row" v-if="parseInt(this.$route.query['flag']) === 2">
        <div style="width: 95px">
          <span style="font-weight: bold">お知らせID: </span>
        </div>
        <div>
          <span class="notifi-id" >{{this.$route.query['id'].toString()}}</span>
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="style-email" >
          <span style="font-weight: bold">Mail to:</span>
        </div>
        <div class="email">
          <div v-if="parseInt(this.$route.query['flag']) === 1" >すべての顧客</div>
          <div v-else-if="parseInt(this.$route.query['flag']) === 2" >{{ this.form.email }}</div>
          <div v-else >{{ this.form.email }}</div>
        </div>
      </div>
      <br>
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
      >
        <div class="row">
          <div class="col-md-12" >

              <div class="row">
                <div class="style-email">
                  <label class="el-form-item__label text-left">件名 <span class="text-danger">*</span></label>
                </div>
                <div class="length-email">
                  <span >100文字以内</span>
                </div>
              </div>

              <div class="row">
                <div class="style-email" >

                </div>
                <div class="length-email">
                  <el-form-item prop="title">
                  <el-input class="title" maxlength="100" v-model="form.title" @blur="form.title = form.title.trim()"></el-input>
                  </el-form-item>
                </div>
              </div>

          </div>
          <div class="col-md-12" >
              <div class="row">
                <div class="style-email">
                  <label class="el-form-item__label text-left">内容 <span class="text-danger">*</span></label>

                </div>
                <div class="length-email">
                  <span>2000文字以内</span>
                </div>
              </div>
              <div class="row">
                <div class="style-email">
                </div>
                <div class="length-email">
                  <el-form-item prop="content">
                  <el-input class="content-email" maxlength="2000" type="textarea" :autosize="{ minRows: 8, maxRows: 10}" v-model="form.content"></el-input>
                  </el-form-item>
                </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-right">
            <el-button class="btn-cancel" @click="redirectNotifi()" >戻る</el-button>
          </div>
          <div class="col-md-6 text-left">
            <el-button class="btn-send-email" type="primary" @click="sendEmail()">送信</el-button>
          </div>
        </div>
        <el-dialog
            title="メールを送信しますか？"
            :visible.sync="dialogSendEmail"
            width="30%"
            center
        >
        <span slot="footer" class="dialog-delete">
          <div class="row">
            <div class="col-md-6 text-right">
                  <el-button class="btn-cancel" @click="dialogSendEmail = false">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left">
                  <el-button type="primary" @click="comfirmSendEmail()">はい</el-button>
            </div>
          </div>
        </span>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { SEND_ALL_EMAIL } from "@/core/services/store/customer.module";
import { GET_EMAIL_CUSTOMER } from "@/core/services/store/order.module";
import { DETAIL_EMAIL } from "@/core/services/store/notifi.module";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ContentEmail",
  data() {
    let checkLengthContent = (rule, value, callback) => {
      if (value !== '' || this.form.content !== '') {
        if (value === '') {
          callback(new Error(this.$t('ER_02')));
        } else if (value.length > 2000) {
           callback(new Error(this.$t('maxLength', {name: '内容', length: '2000'})));
        } else {
          callback();
        }
      }
    };
    let checkLengthTitle = (rule, value, callback) => {
      if (value !== '' || this.form.title !== '') {
        if (value === '') {
          callback(new Error(this.$t('ER_02')));
        } else if (value.length > 100) {
          callback(new Error(this.$t('maxLength', {name: '件名', length: '100'})));
        } else {
          callback();
        }
      }
    };


    return {
      data: {},
      loading: false,
      dialogSendEmail:false,

      form: {
        title:'',
        content:'',
        email: [],
        flag:'',
      },
      rules: {
        title: [
          {required: true, message: this.$t('ER_02'), trigger: 'blur'},
          { validator: checkLengthTitle, trigger: 'blur' },
        ],
        content: [
          {required: true, message: this.$t('ER_02'), trigger: 'blur'},
          { validator: checkLengthContent, trigger: 'blur' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    queryParam() {
      return this.$route.query;
    },
  },
  async created() {
    if(parseInt(this.$route.query['flag']) === 2){
      await this.getDetailEmail();
    }
    if(parseInt(this.$route.query['flag']) === 0){
      await this.getEmailCustomerById();
    }

  },

  methods: {

    sendEmail(){
      if(this.$route.query['flag'] === 1){
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.dialogSendEmail = true;
          }
        });
      }else {
        this.$refs["form"].validate((valid) => {

          if (valid && this.form.email !== []) {
            this.dialogSendEmail = true;
          }
        });
      }

    },
    async comfirmSendEmail(){
      this.$refs["form"].validate(async (valid) => {
        if (valid) {

          if (parseInt(this.$route.query['flag']) === 1) {
            this.loading = true;
            this.dialogSendEmail = false;
            this.form.flag = 1;
            let data = await this.$store.dispatch(SEND_ALL_EMAIL, this.form);
            if (data.code === STATUS_CODE.SUCCESS) {
              this.dialogSendEmail = false;
              this.$router.push({name: "notifi"})
              this.notifySuccess(this.$t("success"));
            }else if(data.code === STATUS_CODE.BAD_REQUEST){
              this.showErrorMessage(data.data, "form");
            }
            this.loading = false;
          }

          if (parseInt(this.$route.query['flag']) === 2) {
            this.loading = true;
            this.dialogSendEmail = false;
            this.form.flag = 0;
            let email = this.form.email;
            this.form.email = email.split(', ');
            let data = await this.$store.dispatch(SEND_ALL_EMAIL, this.form);
            if (data.code === STATUS_CODE.SUCCESS) {
              this.dialogSendEmail = false;
              this.$router.push({name: "notifi"})
              this.notifySuccess(this.$t("success"));
            }else if(data.code === STATUS_CODE.BAD_REQUEST){
              this.form.email = this.form.email.toString();
              this.showErrorMessage(data.data, "form");

            }
            this.loading = false;
          }

          if (parseInt(this.$route.query['flag']) === 0) {
            this.loading = true;
            this.dialogSendEmail = false;
            this.form.flag = 0;
            let email = this.form.email;
            this.form.email = email.split(', ');
            let data = await this.$store.dispatch(SEND_ALL_EMAIL, this.form);

            if (data.code === STATUS_CODE.SUCCESS) {
              this.dialogSendEmail = false;
              this.$router.push({name: "notifi"})
              this.notifySuccess(this.$t("success"));
            }else if(data.code === STATUS_CODE.BAD_REQUEST){
              this.showErrorMessage(data.data, "form");
            }
            this.loading = false;
          }
        }
      });

      },

    async getEmailCustomerById(){

      let ids = { ids: this.$route.query['id'] };
          ids = JSON.parse(JSON.stringify(ids));
        let data = await this.$store.dispatch(GET_EMAIL_CUSTOMER, ids);
        if (data.code === STATUS_CODE.SUCCESS) {
          let email = data.data.split(',');

          for(let i = 0; i < 10; i++){
            if(email[i] !== null && email[i] !== '' && email[i] !== undefined){
              this.form.email.push(email[i])
            }
            else{
              break;
            }
          }
          if(email.length > 10){
            this.form.email.push(' ...')
          }
          this.form.email = this.form.email.join()
        }
        this.loading = false;
      },

    async getDetailEmail() {
      this.loading = true;
      let id = this.$route.query['id'];
      let data = await this.$store.dispatch(DETAIL_EMAIL, id);

      if (data.code === STATUS_CODE.SUCCESS) {
        this.form.content = data.data.content;
        this.form.title = data.data.title;
        let email = data.data.mail_to.split(',');

        for(let i = 0; i < 10; i++){
          if(email[i] !== null && email[i] !== '' && email[i] !== undefined){
            this.form.email.push(email[i])
          }
          else{
            break;
          }
        }
        if(email.length > 10){
          this.form.email.push(' ...')
        }
        this.form.email = this.form.email.join()
      }
      this.loading = false;
    },

    redirectNotifi(){
      this.$router.push({ name: 'notifi'});
    }
  },

};
</script>
<style scoped>
.btn-cancel{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
  padding: 12px 20px;
  width: 120px;
}
.btn-cancel:hover{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
}
.content-email{
  padding-top: 0px;
}
.breadcrumb-icon{
  font-size: 30px;
  padding: 0 5px;
}
button{
  width: 120px;
}
.length-email{
  padding-top: 10px;
  width: 80%;
}
::v-deep .el-dialog__header{
  padding-top: 50px;
}
.style-email{
  width: 95px;
}
</style>